
import Vue, { PropType, VueConstructor } from "vue";

import FormMixins from "@/mixins/form";
import Currency from "@/models/currency";

import { real_op, dollar_op } from "@/currencyOptions";
import { Shipping, ShippingOwnership } from "@/models/shipping";
import { ICurrencyOptions, IEnums } from "@/types/form-types";
import { crudService } from "@/services/crud";

interface IShippingOverFlow {
  text: string;
  value: number;
}

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof FormMixins>>
).extend({
  props: {
    ownership_id: Number,
    ships: {
      type: Array as PropType<Shipping[]>,
      default: () => [],
    },
  },
  mixins: [FormMixins],
  data() {
    return {
      shipping: new Shipping(),
      addShip: false,
      shipping_ownership: new ShippingOwnership(),
      currencySelected: undefined as Currency | undefined,
      // ENUMS
      modals: [
        {
          name: "Aéreo",
          value: "AIR",
        },
        {
          name: "Barcaça",
          value: "MARITIME",
        },
        {
          name: "Ferroviário",
          value: "RAIL",
        },
        {
          name: "Rodoviário",
          value: "ROAD",
        },
      ] as IEnums[],
      products: [
        {
          name: "Fertilizante",
          value: "FERTILIZER",
        },
        {
          name: "Fibra",
          value: "FIBER",
        },
        {
          name: "Grão",
          value: "GRAIN",
        },
      ] as IEnums[],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async save(): Promise<void> {
      if (this.validate()) {
        if (this.addShip) {
          this.shipping.ownership_id = this.ownership_id;
          try {
            const shipping = await crudService.saveClass(
              Shipping,
              Shipping.getResource(),
              this.shipping
            );
            if(shipping.id){
              this.shipping_ownership.shipping_id = shipping.id;
              this.$emit("updateResourceListShipping", shipping);
              this.saveShippingOwnership();
            }else {
              this.$alertError("Problema ao salvar o frete");
              console.error("Shipping without id");
            }
          } catch (error) {
            this.$alertError(this.componentsText.mErrorShip);
            console.error(error);
          }
        } else {
          this.saveShippingOwnership();
        }
      }
    },
    async saveShippingOwnership(): Promise<void> {
      if (this.shipping_ownership.shipping_id) {
        this.setResource("shipping_ownership");
        this.shipping_ownership.ownership_id = this.ownership_id;
        // Mixins
        this.shipping_ownership.quote_date = this.dateISONotNull(
          this.shipping_ownership.quote_date
        );
        // O combobox retorna um objeto
        // seleciono apenas o id para salvar
        if (this.currencySelected?.id)
          this.shipping_ownership.currency_id = this.currencySelected.id;

        try {
          const shippingOwnership = await crudService.saveClass(
            ShippingOwnership,
            ShippingOwnership.getResource(),
            this.shipping_ownership
          );
          this.$alertSuccess(this.componentsText.mSuccess);
          this.$emit("updateResourceListOwnerShipping", shippingOwnership);
          this.close();
        } catch (error) {
          this.$alertError(this.componentsText.mErrorShipOwner);
          console.error(error);
        }
      } else {
        this.$alertError("Selecione uma categoria de frete");
      }
    },
    initialize(): void {
      this.addShip = false;
      this.$nextTick(() => {
        this.shipping = new Shipping();
        this.shipping_ownership = new ShippingOwnership();
        this.currencySelected = undefined;
      });
    },
    newShip(): void {
      this.addShip = true;
    },
    convertEnum(field: string, value: string): string {
      if (field === "product") {
        switch (value) {
          case "GRAIN":
            return "Grão";
          case "FERTILIZER":
            return "Fertilizante";
          case "FIBER":
            return "Fibra";

          default:
            return "Grão";
        }
      } else {
        switch (value) {
          case "RAIL":
            return "Ferroviário";
          case "AIR":
            return "Aéreo";
          case "MARITIME":
            return "Barcaça";
          case "ROAD":
            return "Rodoviário";

          default:
            return "Rodoviário";
        }
      }
    },
    changeCurrency(currency: Currency): void {
      if (currency.identifier === "D")
        this.shipping_ownership.reference_value = undefined;
      else {
        this.shipping_ownership.reference_value = 1;
      }
    },
    toUpdate(item: ShippingOwnership): void {
      this.show = true;
      this.$nextTick(() => {
        this.shipping_ownership = new ShippingOwnership(item);

        if (item.shipping) this.shipping = new Shipping(item.shipping);
        this.currencySelected = this.currencies.find((c: Currency) => {
          return c.id === this.shipping_ownership.currency_id;
        });
      });
    },
  },
  computed: {
    componentsText() {
      if (this.shipping && this.shipping.id) {
        return {
          action: "Atualizar",
          mSuccess: "Frete atualizado com sucesso!",
          mErrorShip: "Houve um problema ao atualizar o frete",
          mErrorShipOwner:
            "Houve um problema ao atualizar o frete da propriedade",
        };
      } else {
        return {
          action: "Adicionar",
          mSuccess: "Frete inserido com sucesso!",
          mErrorShip: "Houve um problema ao inserir o frete",
          mErrorShipOwner:
            "Houve um problema ao inserir o frete da propriedade",
        };
      }
    },
    shipCategory(): IShippingOverFlow[] {
      if (this.ships) {
        return this.ships.map((shipping) => {
          if (shipping.id)
            return {
              text: `De ${shipping.origin.name}-${
                shipping.origin.state.abbreviation
              } para ${shipping.destiny.name}-${
                shipping.destiny.state.abbreviation
              } - Produto: ${this.convertEnum(
                "product",
                shipping.product
              )} - Modal: ${this.convertEnum("modal", shipping.modal)}`,
              value: shipping.id,
            };
          else
            return {
              text: "",
              value: 0,
            };
        });
      }
      return [];
    },
    options(): ICurrencyOptions {
      if (this.currencySelected) {
        if (this.currencySelected.identifier === "D") return dollar_op;
      }
      return real_op;
    },
    hideReferenceValue(): boolean {
      if (this.currencySelected) {
        return this.currencySelected.identifier === "R" ? false : true;
      }
      return true;
    },
  },
  watch: {
    ships(val): void {
      if (val && val.length === 0) {
        this.addShip = true;
      }
    },
  },
});
