import { isEmptyObject, stringToNumber, stringToNumberOrUndefined } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import City from "./city";
import Currency from "./currency";
import Growing from "./growing";
import Harvest from "./harvest";
import Ownership from "./ownership";

export interface IShipping extends IModelBase {
  product: string;
  modal: string;
  is_deleted: boolean;
  deleted_date?: string;
  origin_id: number;
  destiny_id: number;
  ownership_id: number;

  /**Relationship classes */
  origin?: City;
  destiny?: City;
}

export class ShippingDTO extends ModelBase implements IShipping {
  product = "";
  modal = "";
  is_deleted = false;
  deleted_date: string | undefined = undefined;
  origin_id = 0;
  destiny_id = 0;
  ownership_id = 0;

  origin = new City();
  destiny = new City();

  constructor(dto?: IShipping) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.product = dto.product;
      this.modal = dto.modal;
      this.is_deleted = dto.is_deleted;
      this.deleted_date = dto.deleted_date;
      this.origin_id = dto.origin_id;
      this.destiny_id = dto.destiny_id;
      this.ownership_id = dto.ownership_id;

      if(dto.origin)
        this.origin = new City(dto.origin);
      if(dto.destiny)
        this.destiny = new City(dto.destiny);
    }
  }
}

export class Shipping extends ShippingDTO {
  private static resource = "shipping";

  constructor();
  constructor(shipping: IShipping);
  constructor(shipping?: IShipping) {
    super(shipping);
  }

  public static getResource(): string {
    return this.resource;
  }
}

export interface IShippingOwnership {
  id?: number;
  reference_value?: number;
  value: number;
  shipping_id: number;
  harvest_id: number;
  growing_id: number;
  ownership_id: number;
  currency_id: number;
  quote_date: string;
  is_deleted: boolean;
  deleted_date?: string;

  /**Relationship classes */
  shipping?: Shipping;
  currency?: Currency;
  growing?: Growing;
  harvest?: Harvest;
  ownership?: Ownership;
}

export class ShippingOwnershipDTO implements IShippingOwnership {
  id?: number | undefined = undefined;
  reference_value?: number | undefined = undefined;
  value = 0;
  shipping_id = 0;
  harvest_id = 0;
  growing_id = 0;
  ownership_id = 0;
  currency_id = 0;
  quote_date = "";
  is_deleted = false;
  deleted_date?: string | undefined = undefined;

  shipping?: Shipping;
  currency?: Currency;
  growing?: Growing;
  harvest?: Harvest;
  ownership?: Ownership;

  constructor(dto?: IShippingOwnership) {
    if(dto && !isEmptyObject(dto)) {
      this.id = dto.id;
      this.reference_value = stringToNumberOrUndefined(dto.reference_value);
      this.value = stringToNumber(dto.value);
      this.shipping_id = dto.shipping_id;
      this.harvest_id = dto.harvest_id;
      this.growing_id = dto.growing_id;
      this.ownership_id = dto.ownership_id;
      this.currency_id = dto.currency_id;
      this.quote_date = dto.quote_date;
      this.is_deleted = dto.is_deleted;
      this.deleted_date = dto.deleted_date;

      if(dto.shipping)
        this.shipping = new Shipping(dto.shipping);
      if(dto.currency)
        this.currency = new Currency(dto.currency);
      if(dto.growing)
        this.growing = new Growing(dto.growing);
      if(dto.harvest)
        this.harvest = new Harvest(dto.harvest);
      if(dto.ownership)
        this.ownership = new Ownership(dto.ownership);
    }
  }
}

export class ShippingOwnership extends ShippingOwnershipDTO {
  private static resource = "shipping_ownership";

  constructor();
  constructor(shippingOwnership: IShippingOwnership);
  constructor(shippingOwnership?: IShippingOwnership) {
    super(shippingOwnership);
  }

  public static getResource(): string {
    return this.resource;
  }
}
