
import Vue, { VueConstructor } from "vue";

import ShippingForm from "./forms/ShippingForm.vue";
import CrudMixins from "@/mixins/crud";
import Ownership from "@/models/ownership";

import { IFormFuncs, ItemDataTable } from "@/types/form-types";
import { Shipping, ShippingOwnership } from "@/models/shipping";
import { IParams, ISearchResponse } from "@/types/store-types";
import { crudService } from "@/services/crud";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CrudMixins>>
).extend({
  components: {
    ShippingForm,
  },
  mixins: [CrudMixins],
  data() {
    return {
      dialog: false,
      ownerships: [] as Ownership[],
      disableAdd: false,
      ships: [] as Shipping[],
      results: {} as ISearchResponse<ShippingOwnership>,
      headers: [
        {
          text: "Safra",
          tooltip: "Nome da safra",
          align: "left",
          value: "harvest.name",
        },
        {
          text: "Cultura",
          tooltip: "Nome da cultura",
          align: "center",
          value: "growing.name",
        },
        {
          text: "Produto",
          tooltip: "Produto para o envio",
          align: "center",
          value: "shipping.product",
        },
        {
          text: "Modal",
          tooltip: "Tipo de transporte utilizado",
          align: "center",
          value: "shipping.modal",
        },
        {
          text: "Valor (R$)",
          tooltip: "Valor do frete",
          align: "center",
          value: "value",
        },
        {
          text: "Moeda",
          tooltip: "Moeda utilizada para pagamento",
          align: "center",
          value: "currency.name",
        },
        {
          text: "Origem",
          tooltip: "Origem do frete",
          align: "center",
          value: "shipping.origin.name",
        },
        {
          text: "Destino",
          tooltip: "Destino do frete",
          align: "center",
          value: "shipping.destiny.name",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o talhão",
          value: "actions",
          sortable: false,
        },
      ] as ItemDataTable[],
    };
  },
  async mounted() {
    this.localLoading = true;
    let ownership_id = 0;
    if (this.$route.params.ownership_id) {
      ownership_id = parseInt(this.$route.params.ownership_id);
    }
    try {
      this.ownerships = await this.myOwnerships();
      if (this.ownerships && this.ownerships.length > 0) {
        if (ownership_id) {
          const ownership = this.ownerships.find((o) => o.id === ownership_id);
          if (ownership) this.ownershipSelected = ownership.id;
        } else {
          this.ownershipSelected = this.ownerships[0].id;
        }
        await this.searchShippings();
      } else {
        this.disableAdd = true;
      }
    } catch (error) {
      console.error(error);
      this.$alertError("Houve um problema ao carregar as propriedades");
    }
  },
  computed: {
    headerFiltered(): ItemDataTable[] {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return [
          this.headers[2],
          this.headers[3],
          this.headers[4],
          this.headers[6],
          this.headers[7],
          this.headers[this.headers.length - 1],
        ];
      }
      return this.headers;
    },
  },
  methods: {
    async searchShippings(): Promise<void> {
      this.localLoading = true;
      const params: IParams = {
        ownership_id: this.ownershipSelected,
        checkDelete: true,
      };
      try {
        const ships = await crudService.getSimpleResourceClass(
          Shipping,
          Shipping.getResource(),
          params
        );
        if (ships) this.ships = ships.objects;
        this.results = await crudService.getResourceClass(
          ShippingOwnership,
          ShippingOwnership.getResource(),
          params
        );
        this.localLoading = false;
      } catch (error) {
        console.error(error);
        this.$alertError("Houve um problema ao buscar os fretes");
      }
    },
    edit(ushipping_ownership: ShippingOwnership): void {
      const form = this.$refs["form"] as IFormFuncs<ShippingOwnership>;
      if (form && form.toUpdate) form.toUpdate(ushipping_ownership);
      this.openForm();
    },
    enumProduct(product: string): string {
      switch (product) {
        case "GRAIN":
          return "Grão";
        case "FERTILIZER":
          return "Fertilizante";
        case "FIBER":
          return "Fibra";
        default:
          return "Não identificado";
      }
    },
    enumModal(modal: string): string {
      switch (modal) {
        case "RAIL":
          return "Ferroviário";
        case "ROAD":
          return "Rodoviário";
        case "AIR":
          return "Aério";
        case "MARITIME":
          return "Barcaça";
        default:
          return "Não identificado";
      }
    },
    confirmDelete(dshipping_ownership: ShippingOwnership): void {
      this.setResource("shipping_ownership");
      dshipping_ownership.is_deleted = true;
      dshipping_ownership.deleted_date = new Date().toISOString();
      this.update(dshipping_ownership)
        .then(() => {
          this.$alertSuccess("Frete deletedo com sucesso!");
          this.removeFromResourceList(
            this.results.objects,
            dshipping_ownership
          );
        })
        .catch(() => {
          this.$alertError("Houve um problema ao deletar o frete!");
        });
    },
  },
});
